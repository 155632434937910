import Main from '@/views/MAIN/Main.vue';

export default [
  {
    path: '/dataDesk',
    icon: 'ios-home',
    name: 'dataDesk',
    title: '首页',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'md-cog',
    meta: {
      parentCode: 'top_config',
      isSingle: true
    },
    disabled: false,
    component: Main,
    children: [
      {
        path: 'dataDeskMain',
        icon: 'ios-home',
        name: 'dataDeskMain',
        title: '首页',
        component: () => import(/* webpackChunkName: "dataDesk" */ "@/views/COMMON_VIEW/basic_configuration/dataDesk/index.vue")
      },
      {
        path: 'dataDeskMainYB',
        icon: 'ios-home',
        name: 'dataDeskMainYB',
        title: '首页',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/dataDesk/index.vue")
      },
    ]
  },
  {
    path: '/group',
    icon: 'md-cog',
    name: 'baseConfig',
    title: '系统配置',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'md-cog',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'systemBaseConfig',
        icon: 'ios-list-box-outline',
        name: 'systemBaseConfig',
        title: '系统标准配置',
        // webpackChunkName 用于指定该异步模块的 Chunk 名称，相同 Chunk 名称的模块最终会打包在一起
        component: () => import("@/views/COMMON_VIEW/basic_configuration/systemBaseConfig/systemBaseConfig.vue")
      },
      {
        path: 'semesterStandard',
        icon: 'ios-list-box-outline',
        name: 'semesterStandard',
        title: '学期标准配置',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/systemBaseConfig/semesterStandard.vue")
      },
      {
        path: 'schoolSportsStandard',
        icon: 'ios-list-box-outline',
        name: 'schoolSportsStandard',
        title: '体育素质综合评价考核配置',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/systemBaseConfig/schoolSportsStandard.vue")
      },
      {
        path: 'schoolSportsStandardCD',
        icon: 'ios-list-box-outline',
        name: 'schoolSportsStandardCD',
        title: '中考成绩评价标准',
        component: () => import("@/views/CITY_CD/systemBaseConfig/schoolSportsStandardCD.vue")
      },
      {
        path: 'schoolSportsStandardKM',
        icon: 'ios-list-box-outline',
        name: 'schoolSportsStandardKM',
        title: '中考成绩评价标准',
        component: () => import("@/views/CITY_KM/basic_configuration/systemBaseConfig/schoolSportsStandardKM.vue")
      },
      {
        path: 'schoolSportsStandardYB',
        icon: 'ios-list-box-outline',
        name: 'schoolSportsStandardYB',
        title: '体育素质综合评价考核配置',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/basic_configuration/systemConfiguration/schoolSportsStandardYB.vue")
      },
      {
        path: 'appraisalDescription',
        icon: 'ios-list-box-outline',
        name: 'appraisalDescription',
        title: '体育素质综合评价考核说明',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/systemBaseConfig/appraisalDescription.vue")
      },
      {
        path: 'uploadAndDownload',
        icon: 'ios-list-box-outline',
        name: 'uploadAndDownload',
        title: '文件传输管理',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/systemBaseConfig/uploadAndDownload.vue")
      }
    ]
  },
  {
    path: '/schoolManage',
    icon: 'md-easel',
    name: 'schoolManage',
    title: '学校管理',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'ios-settings-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'organManage',
        icon: 'ios-list-box-outline',
        name: 'organManage',
        title: '学校信息',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/organInfo.vue")
      },
      {
        path: 'organInfoOrgan',
        icon: 'ios-list-box-outline',
        name: 'organInfoOrgan',
        title: '机构首页',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/organInfoOrgan.vue")
      },
      {
        path: 'primarySportsClass',
        icon: 'ios-list-box-outline',
        name: 'primarySportsClass',
        title: '班级及课程管理',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/sportsClass.vue")
      },
      {
        path: 'minSportsClass',
        icon: 'ios-list-box-outline',
        name: 'minSportsClass',
        title: '班级及课程管理',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/kindergarten/sportsClass.vue")
      },
      {
        path: 'mineditClass',
        icon: 'ios-list-box-outline',
        name: 'mineditClass',
        title: '幼儿园编辑',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/kindergarten/editClass.vue")
      },
      {
        path: 'primarySportsClassConfig',
        icon: 'ios-list-box-outline',
        name: 'primarySportsClassConfig',
        title: '班级及课程管理',
        component: () => import("@/views/CITY_CD/highSchool/sportsClass/primarySportsClassConfig.vue")
      },
      {
        path: 'sportsStudentManage',
        icon: 'ios-list-box-outline',
        name: 'sportsStudentManage',
        title: '导入体育课分班',
        component: () => import("@/views/CITY_CD/highSchool/sportsClass/sportsStudentManage.vue")
      },
      {
        path: 'classPlanExportCDHigh',
        icon: 'ios-list-box-outline',
        name: 'classPlanExportCDHigh',
        title: '导入体育课分班',
        component: () => import("@/views/CITY_CD/highSchool/sportsClass/classPlanExportCDHigh.vue")
      },
      {
        path: 'sportsStudentClassManage',
        icon: 'ios-list-box-outline',
        name: 'sportsStudentClassManage',
        title: '体育课成员分班',
        component: () => import("@/views/CITY_CD/highSchool/sportsClass/sportsStudentClassManage.vue")
      },
      {
        path: 'classPlanCDHigh',
        icon: 'ios-list-box-outline',
        name: 'classPlanCDHigh',
        title: '体育课排课',
        component: () => import("@/views/CITY_CD/highSchool/sportsClass/classPlan.vue")


      },
      {
        path: 'classPlan',
        icon: 'ios-list-box-outline',
        name: 'classPlan',
        title: '体育课排课',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/classPlan.vue")
      },
      {
        path: 'classPlanExport',
        icon: 'ios-list-box-outline',
        name: 'classPlanExport',
        title: '班级排课信息导入',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/classPlanExport.vue")
      },
      {
        path: 'classPlanExportV2',
        icon: 'ios-list-box-outline',
        name: 'classPlanExportV2',
        title: '班级排课信息导入',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/classPlanExportV2.vue")


      },
      {
        path: 'classTestInfo',
        icon: 'ios-list-box-outline',
        name: 'classTestInfo',
        title: '考试项目',
        component: () => import("@/views/COMMON_VIEW/basic_configuration/schoolManage/classTestInfo.vue")


      },

      {
        path: 'curriculumAdministrationKM',
        icon: 'ios-list-box-outline',
        name: 'curriculumAdministrationKM',
        title: '班级管理',
        component: () => import("@/views/CITY_KM/schoolAdministration/curriculumAdministration.vue")
      },
      {
        path: 'teacherEdit',
        icon: 'ios-list-box-outline',
        name: 'teacherEdit',
        title: '教师设置',
        component: () => import("@/views/CITY_KM/schoolAdministration/teacherEdit.vue")
      }
    ]
  },
  {
    path: '/roleManage',
    icon: 'md-people',
    name: 'roleManage',
    title: '人员管理',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'ios-settings-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'studentInfoManage',
        icon: 'ios-list-box-outline',
        name: 'studentInfoManage',
        title: '学生管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/studentInfoManage.vue'], resolve);
        }
      },
      {
        path: 'applyRemoveStudent',
        name: 'applyRemoveStudent',
        title: '申请删除学生',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/applyRemoveStudent.vue'], resolve);
        }
      },
      {
        path: 'studentAttestationManage',
        icon: 'ios-list-box-outline',
        name: 'studentAttestationManage',
        title: '学生认证信息管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/studentCertificationManagement/index.vue'], resolve);
        }
      },
      {
        path: 'studentCertificationDetail',
        icon: 'ios-list-box-outline',
        name: 'studentCertificationDetail',
        title: '学生认证信息管理详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/studentCertificationManagement/classDetail.vue'], resolve);
        }
      },
      {
        path: 'studentImportPhoto',
        icon: 'ios-list-box-outline',
        name: 'studentImportPhoto',
        title: '导入证件照',
        component: resolve => {
          require(['@/views/COMMON_VIEW/public_import_report/commonImport/baseConfig/personnelManage/studentManage.vue'], resolve);
        }
      },
      {
        path: 'studentInfoDetail',
        icon: 'ios-list-box-outline',
        name: 'studentInfoDetail',
        title: '学生详情',
        props: true,
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/studentInfoDetail.vue'], resolve);
        }
      },
      {
        path: 'minstudentInfoManage',
        icon: 'ios-list-box-outline',
        name: 'minstudentInfoManage',
        title: '学生管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/kindergarten/studentInfoManage.vue'], resolve);
        }
      },
      {
        path: 'minstudentInfoDetail',
        icon: 'ios-list-box-outline',
        name: 'minstudentInfoDetail',
        title: '幼儿园学生详情',
        props: true,
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/kindergarten/studentInfoDetail.vue'], resolve);
        }
      },
      {
        path: 'operatorManage',
        icon: 'ios-list-box-outline',
        name: 'operatorManage',
        title: '教师管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/operatorManage/teacherList.vue'], resolve);
        }
      },
      {
        path: 'teacherDetail',
        icon: 'ios-list-box-outline',
        name: 'teacherDetail',
        title: '人员明细',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/operatorManage/teacherDetail.vue'], resolve);
        }
      },
      {
        path: 'groupDetail',
        icon: 'ios-list-box-outline',
        name: 'groupDetail',
        title: '权限组详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/operatorManage/groupDetail.vue'], resolve);
        }
      },
      {
        path: 'exportTaskManage',
        icon: 'ios-list-box-outline',
        name: 'exportTaskManage',
        title: '学生信息导入管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/exportTaskManage.vue'], resolve);
        }
      },
      {
        path: 'exportTaskReplaceManage',
        icon: 'ios-list-box-outline',
        name: 'exportTaskReplaceManage',
        title: '学生信息导入管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/exportTaskReplaceManage.vue'], resolve);
        }
      },
      {
        path: 'detectRegisterCodeRepeat',
        icon: 'ios-list-box-outline',
        name: 'detectRegisterCodeRepeat',
        title: '检测学籍号号是否重复',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/detectRegisterCodeRepeat.vue'], resolve);
        }
      },
      {
        path: 'unusualData',
        icon: 'ios-list-box-outline',
        name: 'unusualData',
        title: '异常数据明细',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/personnelManagement/primaryschool/unusualData.vue'], resolve);
        }
      },

    ]
  },
  {
    path: '/StudentExempt',
    icon: 'ios-folder-open',
    name: 'StudentExempt',
    title: '免试管理',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'ios-settings-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'StudentExemptCD',
        icon: 'ios-list-box-outline',
        name: 'StudentExemptCD',
        title: '免试管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/exempt_manage/studentExempt.vue'], resolve);
        }
      },
      {
        path: 'exemptionAdministrationKM',
        icon: 'ios-list-box-outline',
        name: 'exemptionAdministrationKM',
        title: '免考管理',
        component: resolve => {
          require(['@/views/CITY_KM/exemptionAdministration/exemption.vue'], resolve);
        }
      },
      {
        path: 'exemptionDetail',
        icon: 'ios-list-box-outline',
        name: 'exemptionDetail',
        title: '免试管理详情',
        component: resolve => {
          require(['@/views/CITY_KM/exemptionAdministration/exemptionDetail.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/highStudentExempt',
    icon: 'ios-folder-open',
    name: 'highStudentExempt',
    title: '免试管理',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'ios-settings-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'highStudentExempt',
        icon: 'ios-list-box-outline',
        name: 'highStudentExempt',
        title: '免试管理',
        component: resolve => {
          require(['@/views/CITY_CD/highSchool/sportsClass/highStudentExempt.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/helperManage',
    icon: 'ios-build',
    name: 'helperManage',
    title: '辅助功能',
    parentCode: 'top_config',
    parentName: '辅助功能',
    parentIcon: 'ios-bug',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'dataValidate',
        icon: 'ios-list-box-outline',
        name: 'dataValidate',
        title: '数据校验',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/helperManage/dataValidate/index.vue'], resolve);
        }
      },
      {
        path: 'dataValidateDetail',
        icon: 'ios-list-box-outline',
        name: 'dataValidateDetail',
        title: '数据校验',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/helperManage/dataValidateDetail/index.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/intelligentTeachingManagement',
    icon: 'md-bookmarks',
    name: 'intelligentTeachingManagement',
    title: '智能教学管理',
    parentCode: 'top_config',
    parentName: '基础配置',
    parentIcon: 'md-cog',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'teachingManage',
        icon: 'ios-list-box-outline',
        name: 'teachingManage',
        title: '智能课堂监控',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/teachingManage.vue'], resolve);
        }
      },
      {
        path: 'deviceAccountManagement',
        icon: 'ios-list-box-outline',
        name: 'deviceAccountManagement',
        title: '测试设备账号管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/deviceAccountManagement.vue'], resolve);
        }
      },
      {
        path: 'bathGroup',
        icon: 'ios-list-box-outline',
        name: 'bathGroup',
        title: '批量分组',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/bathGroup.vue'], resolve);
        }
      },
      {
        path: 'giveDevice',
        icon: 'ios-list-box-outline',
        name: 'giveDevice',
        title: '分配手环',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/giveDevice.vue'], resolve);
        }
      },
      {
        path: 'testDeviceManage',
        icon: 'ios-list-box-outline',
        name: 'testDeviceManage',
        title: '测试任务管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/testTaskManage/index.vue'], resolve);
        }
      },
      {
        path: 'testRecordManage',
        icon: 'ios-list-box-outline',
        name: 'testRecordManage',
        title: '测试记录管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/testRecordManage.vue'], resolve);
        }
      },
      {
        path: 'smartClassMonitor_high',
        icon: 'ios-paper-outline',
        name: 'teachingManageHigh',
        title: '智能课堂监控',
        meta: {
          schoolType: '4',
          scene: '高中'
        },
        component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/index.vue'], resolve); }
      },
      {
        path: 'giveDevice_high',
        icon: 'ios-paper-outline',
        name: 'giveDevice_high',
        title: '分配手环',
        component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/giveDevice.vue'], resolve); }
      },
      {
        path: 'bathGroup_high',
        icon: 'ios-paper-outline',
        name: 'bathGroup_high',
        title: '手环批量分组',
        component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/bathGroup.vue'], resolve); }
      },
    ]
  }
]